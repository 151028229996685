import './index.scss'
// $(".header-nav li").mouseover(function() {
//     $(this).find(".down").stop().slideDown();
// })
// $(".header-nav li").mouseout(function() {
//     $(this).find(".down").stop().slideUp();
// })
$('.header-nav li').click(function(){//点击按钮
    if($(this).find(".down").is(':hidden')){//如果当前隐藏
        $(".down").stop().slideUp();
        $(this).find(".down").stop().slideDown();
    }else{//否则
        $(this).find(".down").stop().slideUp();
    }
})
$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
})
$(".search").click(function(){
    $(".search-box").css("display","flex");
    $("header .nav").hide();
})
$(".search-out").click(function(){
    $("header .nav").show();
    $(".search-box").hide();
})
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})