module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n    <div class="container">\r\n        <div class="nav">\r\n            <div class="logo">\r\n                <a href="index.html">\r\n                    <img src="' +
((__t = ( require('./images/logo.png') )) == null ? '' : __t) +
'" alt="">\r\n                </a>\r\n            </div>\r\n            <ul class="header-nav">\r\n                <li>\r\n                    <a href="javascript:;">品牌</a>\r\n                    <dl class="down">\r\n                        <dt><a href="about.html">关于优利泰科</a></dt>\r\n                        <dt><a href="story.html">品牌故事</a></dt>\r\n                    </dl>\r\n                </li>\r\n                <li>\r\n                    <a href="javascript:;">产品</a>\r\n                    <dl class="down">\r\n                        <dt><a href="household.html">家用场景</a></dt>\r\n                        <dt><a href="product.html">商用场景</a></dt>\r\n                        <dt><a href="javascript:;">医疗/实验室场景</a></dt>\r\n                    </dl>\r\n                </li>\r\n                <li class="shop">\r\n                    <a href="javascript:;">购买</a>\r\n                    <dl class="down">\r\n                        <dt>\r\n                            <a href="javascript:;">\r\n                            <img src="' +
((__t = ( require('./images/jingdong.png') )) == null ? '' : __t) +
'" alt="">\r\n                            <span>京东官方旗舰店</span>\r\n                        </a></dt>\r\n                    </dl>\r\n                </li>\r\n                <li>\r\n                    <a href="javascript:;">服务</a>\r\n                    <dl class="down">\r\n                        <dt><a href="service-guarantee.html">服务保障</a></dt>\r\n                        <dt><a href="download.html">产品使用手册下载</a></dt>\r\n                    </dl>\r\n                </li>\r\n                <div class="function">\r\n                    <li class="phone">\r\n                        <a href="javscript:;">\r\n                            <div class="pic">\r\n                                <img src="' +
((__t = ( require('./images/header-phone.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <dl class="down">\r\n                                <dt>400-066-9926</dt>\r\n                            </dl>\r\n                        </a>\r\n                    </li>\r\n                    <!-- <li class="login">\r\n                        <a href="register.html" class="unlisted">\r\n                            <div class="pic">\r\n                                <img src="' +
((__t = ( require('./images/login.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                        </a>\r\n                        <a href="javascript:;" class="record" style="display: none;">\r\n                            <div class="pic">\r\n                                <img src="' +
((__t = ( require('./images/login1.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                        </a>\r\n                    </li> -->\r\n                    <!-- <li class="search">\r\n                        <a href="javascript:;">\r\n                            <div class="pic">\r\n                                <img src="' +
((__t = ( require('./images/header-search.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                        </a>\r\n                    </li> -->\r\n                </div>\r\n            </ul>\r\n            <div class="phone-nav">\r\n                <!-- <div class="login">\r\n                    <a href="register.html" class="unlisted">\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/login.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                    </a>\r\n                    <a href="javascript:;" class="record" style="display: none;">\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/login1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                    </a>\r\n                </div> -->\r\n                <div class="open-menu" data-open="cmenu">\r\n                    <div class="hamburger hamburger-11">\r\n                        <div></div>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n        <!-- <div class="search-box">\r\n            <div class="content">\r\n             <input type="text" placeholder="输入关键词回车">\r\n             <img src="' +
((__t = ( require('./images/search-out.png') )) == null ? '' : __t) +
'" alt="" class="search-out">\r\n            </div>\r\n         </div> -->\r\n        <div class="phone-header">\r\n            <div class="mob-nav">\r\n                <ul>\r\n                    <li class="nLi">\r\n                        <h3 class="nav-title">\r\n                            <a href="index.html">首页</a>\r\n                        </h3>\r\n                    </li>\r\n                    <li class="nLi">\r\n                        <h3 class="nav-title">\r\n                            <a href="javascript:;">品牌</a>\r\n                            <div class="arrow">\r\n                                <img src="' +
((__t = ( require('./images/arrow.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                        </h3>\r\n                        <ul class="sub">\r\n                            <li>\r\n                                <a href="about.html">关于优利泰科</a>\r\n                            </li>\r\n                            <li>\r\n                                <a href="story.html">品牌故事</a>\r\n                            </li>\r\n                        </ul>\r\n                    </li>\r\n                    <li class="nLi">\r\n                        <h3 class="nav-title">\r\n                            <a href="javascript:;">产品</a>\r\n                            <div class="arrow">\r\n                                <img src="' +
((__t = ( require('./images/arrow.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                        </h3>\r\n                        <ul class="sub">\r\n                            <li><a href="household.html">家用场景</a></li>\r\n                            <li><a href="product.html">商用场景</a></li>\r\n                            <li><a href="javascript:;">医疗/实验室场景</a></li>\r\n                        </ul>\r\n                    </li>\r\n                    <li class="nLi">\r\n                        <h3 class="nav-title">\r\n                            <a href="javascript:;">购买</a>\r\n                            <div class="arrow">\r\n                                <img src="' +
((__t = ( require('./images/arrow.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                        </h3>\r\n                        <ul class="sub">\r\n                            <li><a href="javascript:;"><img src="' +
((__t = ( require('./images/jingdong.png') )) == null ? '' : __t) +
'" alt="">京东官方旗舰店</a></li>\r\n                        </ul>\r\n                    </li>\r\n                    <li class="nLi">\r\n                        <h3 class="nav-title">\r\n                            <a href="javascript:;">服务</a>\r\n                            <div class="arrow">\r\n                                <img src="' +
((__t = ( require('./images/arrow.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                        </h3>\r\n                        <ul class="sub">\r\n                            <li><a href="service-guarantee.html">服务保障</a></li>\r\n                            <li><a href="download.html">产品使用手册下载</a></li>\r\n                        </ul>\r\n                    </li>\r\n                </ul>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</header>\r\n';

}
return __p
}