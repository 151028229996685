module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="container">\r\n        <div class="serve">\r\n            <a href="javascript:;">\r\n                <div class="text">\r\n                    <h2>线上购买渠道</h2>\r\n                    <p>官方旗舰店</p>\r\n                </div>\r\n                <div class="more">\r\n                    <img src="' +
((__t = ( require('./images/footer-more.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n            </a>\r\n            <a href="stores.html">\r\n                <div class="text">\r\n                    <h2>线下购买渠道</h2>\r\n                    <p>欢迎莅临</p>\r\n                </div>\r\n                <div class="more">\r\n                    <img src="' +
((__t = ( require('./images/footer-more.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n            </a>\r\n            <a href="service-guarantee.html">\r\n                <div class="text">\r\n                    <h2>服务支持</h2>\r\n                    <p>提供尊享服务体验</p>\r\n                </div>\r\n                <div class="more">\r\n                    <img src="' +
((__t = ( require('./images/footer-more.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n            </a>\r\n        </div>\r\n        <ul class="touch">\r\n            <li>\r\n                <h3>\r\n                    <img src="' +
((__t = ( require('./images/footer-icon.png') )) == null ? '' : __t) +
'" alt="">\r\n                    全国服务热线\r\n                </h3>\r\n                <a href="tel://400-066-9926">400-066-9926</a>\r\n            </li>\r\n            <li>\r\n                <img src="' +
((__t = ( require('./images/ewm1.png') )) == null ? '' : __t) +
'" alt="">\r\n                <p>更多权益，请扫码关注官方公众号</p>\r\n            </li>\r\n            <li>\r\n                <img src="' +
((__t = ( require('./images/ewm2.png') )) == null ? '' : __t) +
'" alt="">\r\n                <p>更多权益，请扫码关注官方微博</p>\r\n            </li>\r\n        </ul>\r\n    </div>\r\n    <div class="copy">\r\n        <div class="container">\r\n            <div class="left">\r\n                <a href="javascript:;">2023 All Rights Reserved <span>鲁ICP备2023014525号</span></a>\r\n                <img src="' +
((__t = ( require('./images/copy.jpg') )) == null ? '' : __t) +
'" alt="">\r\n                <a href="javascript:;">隐私政策</a>\r\n            </div>\r\n            <!-- <div class="right">\r\n                <a href="javascript:;" class="wx">\r\n                    <img src="' +
((__t = ( require('./images/wx-icon.png') )) == null ? '' : __t) +
'" alt="">\r\n                </a>\r\n                <a href="javascript:;" class="wb">\r\n                    <img src="' +
((__t = ( require('./images/wb-icon.png') )) == null ? '' : __t) +
'" alt="">\r\n                </a>\r\n            </div> -->\r\n        </div>\r\n        <div class="backtop">\r\n            <img src="' +
((__t = ( require('./images/back.png') )) == null ? '' : __t) +
'" alt="">\r\n            <p>回到顶部</p>\r\n        </div>\r\n    </div>\r\n</footer>\r\n<div class="ph-footer">\r\n    <div class="ph-container">\r\n        <div class="serve">\r\n            <a href="javascript:;">\r\n                <div class="text">\r\n                    <h2>线上购买渠道</h2>\r\n                    <p>官方旗舰店</p>\r\n                </div>\r\n                <div class="more">\r\n                    <img src="' +
((__t = ( require('./images/footer-more.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n            </a>\r\n            <a href="stores.html">\r\n                <div class="text">\r\n                    <h2>线下购买渠道</h2>\r\n                    <p>欢迎莅临</p>\r\n                </div>\r\n                <div class="more">\r\n                    <img src="' +
((__t = ( require('./images/footer-more.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n            </a>\r\n            <a href="service-guarantee.html">\r\n                <div class="text">\r\n                    <h2>服务支持</h2>\r\n                    <p>提供尊享服务体验</p>\r\n                </div>\r\n                <div class="more">\r\n                    <img src="' +
((__t = ( require('./images/footer-more.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n            </a>\r\n        </div>\r\n        <ul class="touch">\r\n            <li>\r\n                <img src="' +
((__t = ( require('./images/ewm1.png') )) == null ? '' : __t) +
'" alt="">\r\n                <p>更多权益，请扫码关注官方公众号</p>\r\n            </li>\r\n            <li>\r\n                <img src="' +
((__t = ( require('./images/ewm2.png') )) == null ? '' : __t) +
'" alt="">\r\n                <p>更多权益，请扫码关注官方微博</p>\r\n            </li>\r\n            <li>\r\n                <h3>\r\n                    <img src="' +
((__t = ( require('./images/footer-icon.png') )) == null ? '' : __t) +
'" alt="">\r\n                    全国服务热线\r\n                </h3>\r\n                <a href="tel://400-066-9926">400-066-9926</a>\r\n            </li>\r\n        </ul>\r\n        <div class="ph-icon">\r\n            <a href="javascript:;" class="wx">\r\n                <img src="' +
((__t = ( require('./images/wx-icon.png') )) == null ? '' : __t) +
'" alt="">\r\n            </a>\r\n            <a href="javascript:;" class="wb">\r\n                <img src="' +
((__t = ( require('./images/wb-icon.png') )) == null ? '' : __t) +
'" alt="">\r\n            </a>\r\n            <div class="backtop">\r\n                <img src="' +
((__t = ( require('./images/goback.png') )) == null ? '' : __t) +
'" alt="">\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div class="copy">\r\n        <a href="javascript:;">\r\n            <span>2023 All Rights Reserved </span>\r\n            <span>鲁ICP备2023014525号</span>\r\n        </a>\r\n        <img src="' +
((__t = ( require('./images/copy.jpg') )) == null ? '' : __t) +
'" alt="">\r\n        <a href="javascript:;">隐私政策</a>\r\n    </div>\r\n</div>';

}
return __p
}